<template>
  <div class="layout-actionbar p-p-4">
    <div class="layout-breadcrumb">
      <span
        v-if="$route.meta.breadcrumb && $route.meta.breadcrumb[0]"
        class="currrent-page"
      >
        {{ $route.meta.breadcrumb[0].parent }}
        <span v-if="$route.meta.breadcrumb[0].label">
          <i class="pi pi-fw pi-chevron-right p-mr-1" />
        </span>
        {{ $route.meta.breadcrumb[0].label }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
span {
  color: $actionbarColor !important;
}
i {
  font-size: 0.8rem;
}
.current-page {
  font-size: 15px;
  font-weight: 500;
}
.confirmation-content {
  display: flex;
}
.triangle-icon {
  font-size: 2rem;
}
.confirm-btn {
  width: 55px !important;
}
</style>
