<template>
  <div class="feedback-dialogue-container">
    <Dialog
      v-model:visible="canShowFeedbackForm"
      class="feedback-dialogue"
      :closable="true"
      :close-on-escape="true"
      :dismissable-mask="true"
      :modal="true"
      :draggable="false"
      @show="loadFeedbackTypes"
      @hide="resetData()"
    >
      <template #header>
        <h3>Feedback form</h3>
      </template>
      <form class="feedback-form" @submit.prevent="submitFeedback()">
        <div class="feedback-input-container p-mb-3">
          <span class="p-mb-2" for="feedback-type"> Feedback type *</span>
          <Dropdown
            id="feedback-type"
            v-model="feedbackType"
            class=""
            type="text"
            :options="feedbackTypes"
            option-label="name"
            option-value="value"
          />
          <div class="p-error">
            <small
              v-if="v$.$dirty && v$.feedbackType.required.$invalid"
              class="p-error"
              >Please select a feedback type</small
            >
          </div>
        </div>
        <div class="feedback-input-container p-mb-5">
          <span class="p-mb-2" for="feedback-type">
            Enter your feedback here *
          </span>
          <tiny-text-editor v-model="feedback" :show-toolbar="false" />
          <div class="p-error">
            <small
              v-if="v$.$dirty && v$.feedback.required.$invalid"
              class="p-error"
              >Please enter your feedback</small
            >
          </div>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button type="Submit" label="Submit" />
        </div>
      </form>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import TinyTextEditor from '../components/TinyTextEditor.vue';
export default {
  components: { TinyTextEditor },
  emits: ['closeFeedbackForm'],
  data() {
    return {
      feedbackType: null,
      feedbackTypes: [],
      feedback: '',
      v$: useValidate(),
    };
  },
  validations() {
    return {
      feedbackType: { required, $autoDirty: true },
      feedback: { required, $autoDirty: true },
    };
  },
  computed: {
    ...mapGetters(['showFeedbackForm']),
    canShowFeedbackForm: {
      get: function () {
        return this.showFeedbackForm;
      },
      set: function () {
        this.updateShowFeedbackForm(false);
      },
    },
  },
  methods: {
    ...mapActions(['updateShowFeedbackForm']),
    loadFeedbackTypes() {
      this.v$.$reset();
      let that = this;
      this.$http.get(`/feedbacks/types`).then((res) => {
        that.feedbackTypes = res.data;
      });
    },
    submitFeedback() {
      this.v$.$touch();
      if (!this.v$.$error) {
        let that = this;
        this.$http
          .post(`/feedbacks`, {
            feedback: {
              feedback_type: this.feedbackType,
              comment: this.feedback,
            },
          })
          .then((_) => {
            that.$moshaToast('Your feedback has been submitted', {
              type: 'success',
              showIcon: true,
            });
            that.updateShowFeedbackForm(false);
          });
      }
    },
    resetData() {
      this.feedbackType = null;
      this.feedback = '';
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss">
.feedback-dialogue {
  .p-inputtext {
    resize: none;
  }
  width: 35rem;
  .feedback-form {
    width: 90%;
    margin: auto;
    .feedback-input-container {
      display: flex;
      flex-direction: column;
      textarea {
        min-height: 300px;
        width: 35rem;
        max-width: 90%;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.feedback-form {
  .feedback-dialogue {
    .p-dialogue-content {
      .feedback-input-container {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
