import axios from 'axios';
import Qs from 'qs';

import store from '@/store/index';
import { app } from '@/main.js';

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_RAILS_API_URL
    : 'http://localhost:3000/api';
axios.defaults.withCredentials = true;
axios.defaults.headers.Accept = 'application/json';
// axios.defaults.headers.X_REQUESTED_WITH = 'XMLHttpRequest';

axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    // Qs is already included in the Axios package
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false,
    });
  };

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401) {
      app.config.globalProperties.$moshaToast('Please login again', {
        type: 'danger',
        duration: 2000,
      });
      store.dispatch('resetLogin');
    }
    return Promise.reject(error);
  }
);
