<template>
  <div :class="`pp-content ${loading ? 'pp-loading' : ''}`">
    <i v-if="loading" class="pi pi-spin pi-spinner pp-loader"></i>
    <div v-else v-html="content" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: '',
      loading: false,
    };
  },
  created() {
    this.getPrivacyPolicy();
  },
  methods: {
    getPrivacyPolicy() {
      this.loading = true;
      this.$http.get('privacy_policy').then((res) => {
        this.content = res.data.content;
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.pp-content {
  background: white;
  padding: 5rem 2rem;
}
.pp-loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .pp-loader {
    font-size: 4rem;
  }
}
</style>
<style lang="scss">
.pp-content > div > section > .policy-container {
  color: black;
}
</style>
