<template>
  <body class="login-body">
    <div class="login-container">
      <div class="text-container">
        <div class="quote-sec">
          <p class="app-quote">
            From "just matched" <br />
            to "just married" <br />
            at <b>SoulSearch</b>
          </p>
        </div>
      </div>
      <div class="p-col-4 login-card p-overflow-auto">
        <div class="login-content">
          <img src="/Finalappicon.png" />
          <h1 class="p-text-center">Sign Up</h1>
          <form @submit.prevent="register()">
            <div>
              <div class="login-input">
                <InputText
                  v-model.trim="user.first_name"
                  type="text"
                  placeholder="First Name"
                />
                <div class="p-error">
                  <small
                    v-if="
                      user.first_name !== '' &&
                      v$.user.first_name.charSpaceDot.$invalid
                    "
                    class="p-error"
                    >Only alphabets - space - dot are allowed</small
                  >
                  <small
                    class="p-error"
                    :class="
                      submitClicked && v$.user.first_name.required.$invalid
                        ? null
                        : 'hide-err'
                    "
                    >First name required</small
                  >
                </div>
              </div>
              <div class="login-input">
                <InputText
                  v-model.trim="user.last_name"
                  type="text"
                  placeholder="Last Name"
                />
                <div class="p-error">
                  <small
                    v-if="
                      user.last_name !== '' &&
                      v$.user.last_name.charSpaceDot.$invalid
                    "
                    class="p-error"
                    >Only alphabets - space - dot are allowed</small
                  >
                  <small
                    :class="
                      submitClicked && v$.user.last_name.required.$invalid
                        ? null
                        : 'hide-err'
                    "
                    class="p-error"
                    >Last name required</small
                  >
                </div>
              </div>
              <div class="login-input">
                <Dropdown
                  v-model.number="user.gender"
                  :options="genderOptions"
                  option-label="display"
                  option-value="value"
                  class="drop-down"
                  placeholder="Gender"
                />
                <div class="p-error">
                  <small
                    :class="
                      submitClicked && v$.user.gender.required.$invalid
                        ? null
                        : 'hide-err'
                    "
                    class="p-error"
                    >Enter This Field</small
                  >
                </div>
              </div>
              <div class="login-input">
                <InputText
                  v-model.trim="user.email"
                  type="email"
                  placeholder="Email"
                />
                <div class="p-error">
                  <small v-if="v$.user.email.email.$invalid" class="p-error"
                    >Invalid e-mail address</small
                  >
                  <small
                    class="p-error"
                    :class="
                      submitClicked && v$.user.email.required.$invalid
                        ? null
                        : 'hide-err'
                    "
                    >E-mail required</small
                  >
                </div>
              </div>
              <div class="login-input">
                <InputText
                  v-model.trim="user.password"
                  type="password"
                  placeholder="Password"
                  autocomplete="new-password"
                />
                <div class="p-error">
                  <small
                    v-if="v$.user.password.minLength.$invalid"
                    class="p-error"
                  >
                    <li>Password must contain minumum 8 characters</li>
                  </small>
                  <small
                    v-if="
                      v$.user.password.case.$invalid &&
                      user.password.length >= 2
                    "
                    class="p-error"
                  >
                    <li>
                      must have at least 1 uppercase, 1 lowercase and 1 number
                    </li>
                  </small>
                  <small
                    v-else
                    :class="
                      submitClicked && v$.user.password.required.$invalid
                        ? null
                        : 'hide-err'
                    "
                    >Password can't be empty</small
                  >
                </div>
              </div>
              <div class="login-input">
                <InputText
                  v-model.trim="passwordConfirmation"
                  type="password"
                  placeholder="Confirm Password"
                />
                <div class="p-error">
                  <small
                    v-if="
                      submitClicked && v$.passwordConfirmation.required.$invalid
                    "
                    class="p-error"
                    >Password can't be empty</small
                  >
                  <small
                    v-else-if="
                      v$.passwordConfirmation.sameAs.$invalid &&
                      !v$.passwordConfirmation.required.$invalid &&
                      !v$.passwordConfirmation.minLength.$invalid
                    "
                    class="p-error"
                    >Passwords do not match</small
                  >
                  <small
                    v-else
                    :class="
                      submitClicked &&
                      v$.passwordConfirmation.minLength.$invalid
                        ? null
                        : 'hide-err'
                    "
                    class="p-error"
                    >Password must contain minumum 8 characters</small
                  >
                </div>
              </div>
              <div class="login-input">
                <Dropdown
                  v-model="user.created_for"
                  :options="createdForOptions"
                  option-label="display"
                  option-value="value"
                  class="drop-down"
                  placeholder="Created For"
                />
                <div class="p-error">
                  <small
                    class="p-error"
                    :class="
                      submitClicked && v$.user.created_for.required.$invalid
                        ? null
                        : 'hide-err'
                    "
                    >Please enter a valid created for</small
                  >
                </div>
              </div>
              <div>
                <div class="p-field-checkbox">
                  <input
                    id="checkOption1"
                    type="checkbox"
                    @change="checked = !checked"
                  />
                  <label for="checkOption1" class="p-pt-3 c-tc-l tc-text"
                    >I agree to the
                    <span class="tc" @click="showTC"
                      >terms and conditions</span
                    ></label
                  >
                </div>
              </div>
            </div>
            <Button
              class="p-text-bold p-mb-5 p-mt-1 login-button"
              type="submit"
              :disabled="!checked || loading"
            >
              <span class="p-button-label"
                ><i v-if="loading" class="fa fa-spinner fa-spin p-mr-2" />Sign
                Up</span
              >
            </Button>
            <div class="forgetpassword-container p-d-flex p-jc-center">
              <div>
                <label class="c-tc-l">Already a member? </label>
                <router-link to="/login" class="forget-password"
                  >Sign In</router-link
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div>
      <tc-policy-dialog
        v-if="displayTC"
        :content="contentTC"
        @whenClose="hideTC"
      />
    </div>
    <Message
      v-if="display"
      severity="error"
      class="name1"
      :closable="false"
      :life="50000"
      :sticky="false"
      >{{ errorMessage }}</Message
    >
  </body>
</template>

<script>
import TcPolicyDialog from '@/components/TcPolicyDialog.vue';
import { formFieldMixin } from '@/mixins/formFieldMixin';
import axios from 'axios';
import useValidate from '@vuelidate/core';
import { required, email, minLength, sameAs } from '@vuelidate/validators';
const charSpaceDot = (value) => new RegExp(/^[a-zA-Z .]+$/).test(value);
export default {
  components: {
    TcPolicyDialog,
  },
  mixins: [formFieldMixin],
  data() {
    return {
      displayTC: false,
      loading: false,
      contentTC: '',
      genderOptions: [
        { display: 'Male', value: 0 },
        { display: 'Female', value: 1 },
      ],
      v$: useValidate(),
      submitClicked: false,
      errorMessage: '',
      display: false,
      checked: false,
      createdForOptions: [
        { display: 'Son', value: 'son' },
        { display: 'Daughter', value: 'daughter' },
        { display: 'Brother', value: 'brother' },
        { display: 'Sister', value: 'sister' },
        { display: 'Friend', value: 'friend' },
        { display: 'Self', value: 'myself' },
      ],
      select: '',
      user: {
        first_name: '',
        last_name: '',
        gender: null,
        email: '',
        password: '',
        created_for: null,
      },
      passwordConfirmation: '',
    };
  },
  mounted() {
    this.v$.$validate();
    this.getTC();
  },
  validations() {
    return {
      user: {
        first_name: {
          required,
          charSpaceDot,
        },
        last_name: {
          required,
          charSpaceDot,
        },
        gender: { required },
        email: { required, email },
        password: {
          required,
          minLength: minLength(8),
          case(val) {
            return this.passwordValidator(val);
          },
        },
        created_for: { required },
      },
      passwordConfirmation: {
        required,
        minLength: minLength(8),
        sameAs: sameAs(this.user.password),
      },
    };
  },
  methods: {
    showTC() {
      this.displayTC = true;
    },
    hideTC() {
      this.displayTC = false;
    },
    getTC() {
      let that = this;
      this.$http.get('get_policy').then((res) => {
        that.contentTC = res.data.content;
      });
    },
    register() {
      this.v$.$validate();
      this.submitClicked = true;
      if (!this.v$.$error) {
        this.loading = true;
        let that = this;
        axios
          .post('/users', { user: this.user })
          .then(() => {
            that.loading = false;
            that.$router.push('/login');
            that.$moshaToast(
              'Your account has been successfully registered. Please verify your email to continue',
              { type: 'info', showIcon: true, timeout: 2000 }
            );
          })
          .catch((err) => {
            that.loading = false;
            that.$moshaToast(err.response.data.message, {
              type: 'danger',
              showIcon: true,
              timeout: 2000,
            });
          });
      }
    },
    showMessage(msg) {
      this.display = true;
      if (msg.includes('.')) {
        let arr = msg.split('.');
        this.errorMessage = arr[arr.length - 1];
      } else {
        this.errorMessage = msg;
      }
      setTimeout(() => {
        this.display = false;
        this.errorMessage = '';
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.p-dialog .p-dialog-footer {
  padding: 1rem;
  .p-button {
    margin: 0px;
  }
}
.dialog-tc {
  margin-left: 200px;
  margin-right: 200px;
  width: 40%;
  height: 80%;
}
.name1 {
  position: absolute;
  right: 0px;
}
.p-inputtext:focus {
  box-shadow: none !important;
}
@media screen and (max-width: 800px) {
  .dialog-tc {
    margin-left: 50px;
    margin-right: 50px;
    width: 70%;
  }
}
@media screen and (min-width: 800px) and (max-width: 1300px) {
  .dialog-tc {
    margin-left: 80px;
    margin-right: 80px;
    width: 60%;
  }
}
</style>
<style scoped lang="scss">
.content-tc {
  font-size: 11px;
}
.tc {
  cursor: pointer;
}
.tc-text {
  .tc {
    color: $primaryColor;
  }
}
.drop-down {
  width: 100%;
}
.login-button {
  padding: 0.7em;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  background-color: $primaryColor;
}
.login-button:disabled {
  background-color: $primaryLightColor;
  border: 1px solid $primaryLightColor;
  opacity: 1;
}
img {
  width: 30%;
  margin-left: 35%;
}
.name-underline {
  padding-bottom: 14px;
  width: 5.4em;
  border-bottom: 3px solid $primaryColor;
}
@media screen and (max-height: 870px) {
  .login-card {
    height: 95vh;
  }
}
</style>
