<template>
  <Login v-if="$route.path === '/login'" />
  <Home v-else-if="$route.path === '/' && $route.path === currentPath" />
  <SignUp v-else-if="$route.path === '/signup'" />
  <ForgotPassword v-else-if="$route.path === '/forgot_password'" />
  <Error v-else-if="$route.path === '/error'" />
  <Access v-else-if="$route.path === '/access'" />
  <NotFound v-else-if="$route.path === '/notfound'" />
  <PrivacyPolicy v-else-if="$route.path === '/privacyPolicy'" />
  <App
    v-else
    :theme="theme"
    :layout="layout"
    @theme-change="onThemeChange"
    @layout-change="onLayoutChange"
  />
</template>

<script>
import App from './App.vue';
import Error from './pages/Error';
import Access from './pages/Access';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import PrivacyPolicy from '@/pages/PrivacyPolicy';

export default {
  components: {
    App: App,
    Error: Error,
    Access: Access,
    Login: Login,
    NotFound: NotFound,
    SignUp: SignUp,
    ForgotPassword: ForgotPassword,
    Home: Home,
    PrivacyPolicy: PrivacyPolicy,
  },
  data() {
    return {
      theme: 'blue',
      layout: 'layout-blue',
      currentPath: null,
    };
  },
  created() {
    this.currentPath = window.location.pathname;
  },
  methods: {
    onThemeChange(theme) {
      this.theme = theme;
    },
    onLayoutChange(layout) {
      this.layout = layout;
    },
  },
};
</script>
