import { createStore } from 'vuex';
// import auth from './auth';
import axios from 'axios';
import router from '@/router';
import VuexPersist from 'vuex-persist';

const vuexLocalStorage = new VuexPersist({
  key: 'soulSearch',
  storage: window.localStorage,
});

const store = createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    restrict: false,
    authUser: {},
    matches: [],
    selectOptions: {},
    showFeedbackForm: false,
  },
  mutations: {
    authUser(state, userData) {
      state.authUser = userData;
    },
    matches(state, payload) {
      state.matches = payload;
    },
    setSelectOptions(state, payload) {
      state.selectOptions = payload;
    },
    setShowFeedbackForm(state, payload) {
      state.showFeedbackForm = payload;
    },
  },
  actions: {
    setAuthUser({ commit }, data) {
      commit('authUser', data);
    },
    resetLogin({ commit }) {
      commit('authUser', {});
      router.push('/login');
    },
    async getCurrentUserProfile({ commit }) {
      await axios.get('/users/my_profile').then((res) => {
        commit('authUser', res.data.data.attributes);
      });
    },
    logOut({ dispatch }, allDevice) {
      axios
        .delete(`logout`, {
          data: {
            handleErrors: false,
            delete_all_session: allDevice,
          },
        })
        .then((_res) => {
          dispatch('resetLogin');
        })
        .catch((error) => {
          if (error) {
            dispatch('resetLogin');
          }
        });
    },
    fetchMatches({ commit }) {
      axios.get('users/home_page').then((res) => {
        commit('matches', res.data.new_matches.data);
      });
    },
    getSelectOptions({ commit }) {
      axios
        .get('/users/select_options?from_page=edit_profile')
        .then((response) => {
          commit('setSelectOptions', response.data);
        });
    },
    updateShowFeedbackForm({ commit }, canShowFeedbackForm) {
      commit('setShowFeedbackForm', canShowFeedbackForm);
    },
  },
  getters: {
    canViewAdminStats: (state) => {
      return state.authUser.user_access?.can_view_admin_stats;
    },
    canViewProfiles: (state) => {
      return state.authUser.user_access?.can_view_profiles;
    },
    canViewPendingApproval: (state) => {
      return state.authUser.user_access?.can_view_pending_approval;
    },
    canViewFeedbacks: (state) => {
      return state.authUser.user_access?.can_view_feedbacks;
    },
    canSubmitFeedback: (state) => {
      return state.authUser.user_access?.can_submit_feedback;
    },
    canViewMatches: (state) => {
      return state.authUser.user_access?.can_view_matches;
    },
    canViewMailBox: (state) => {
      return state.authUser.user_access?.can_view_mail_box;
    },
    canViewMyProfile: (state) => {
      return state.authUser.user_access?.can_view_my_profile;
    },
    canApprovePhoto: (state) => {
      return state.authUser.user_access?.can_approve_photo;
    },
    canApproveProfile: (state) => {
      return state.authUser.user_access?.can_approve_profile;
    },
    canDeleteProfile: (state) => {
      return state.authUser.user_access?.can_delete_profile;
    },
    selectOptions: (state) => {
      return state.selectOptions;
    },
    authUser: (state) => {
      return state.authUser;
    },
    loggedIn: (_, getters) => {
      return (
        getters.authUser !== null &&
        getters.authUser.id !== null &&
        getters.authUser.id !== '' &&
        getters.authUser.id !== undefined
      );
    },
    matches: (state) => {
      return state.matches;
    },
    gender: (getters) => {
      return getters.authUser.gender;
    },
    showFeedbackForm: (state) => {
      return state.showFeedbackForm;
    },
  },
});

export default store;
