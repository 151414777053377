<template>
  <Dialog
    v-model:visible="logoutConfirmation"
    header="Confirmation"
    :modal="true"
    :closable="true"
    :draggable="false"
    :close-on-escape="true"
    :dismissable-mask="true"
    class="log-out"
    @hide="hideDialog"
  >
    <div class="p-d-flex p-flex-column p-ai-start">
      <div class="p-d-flex p-ai-center">
        <i class="pi pi-exclamation-triangle p-mr-3 triangle-icon" />
        <span>Are you sure you want to logout?</span>
      </div>
      <div class="p-d-flex p-ai-center p-mt-3">
        <Checkbox id="logout_check" v-model="logoutFromAll" :binary="true" />
        <label for="logout_check" class="p-ml-3">Logout from all devices</label>
      </div>
    </div>
    <template #footer>
      <Button
        label="Yes"
        class="p-button-primary p-button-outlined p-mr-3 confirm-btn"
        @click="logout()"
      />
      <Button
        label="No"
        class="p-button-primary confirm-btn"
        autofocus
        @click="hideDialog()"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  props: {
    logoutDialog: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hideDialog'],
  data() {
    return {
      logoutConfirmation: this.logoutDialog,
      logoutFromAll: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('logOut', this.logoutFromAll);
      this.hideDialog();
    },
    hideDialog() {
      this.$emit('hideDialog');
    },
  },
};
</script>

<style lang="scss">
.log-out {
  width: 500px;
  max-width: 90%;
}
</style>
<style scoped lang="scss">
.confirm-btn {
  width: 55px !important;
}
.triangle-icon {
  font-size: 2rem;
}
</style>
