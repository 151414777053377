<template>
  <div class="not-found">
    <div class="p-d-flex p-flex-column p-ai-center">
      <img class="res-img" src="assets/images/404-page.png" />
      <Button
        v-if="loggedIn"
        label="Go To Dashboard"
        @click="goToDashboard()"
      />
      <Button v-else label="Go To Login page" @click="goToLogin()" />
    </div>
  </div>
</template>

<script>
import { redirectMixin } from '@/mixins/redirectMixin';
import { mapGetters } from 'vuex';

export default {
  mixins: [redirectMixin],
  computed: {
    ...mapGetters(['loggedIn']),
  },
};
</script>

<style scoped lang="scss">
.not-found {
  background-color: white;
  height: 100vh;
  display: flex;
  overflow: auto;
  align-items: center;
  .res-img {
    width: 100%;
    height: auto;
    max-width: 450px;
  }
  .p-d-flex {
    width: 100%;
  }
}
</style>
