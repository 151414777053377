import { createRouter, createWebHistory } from 'vue-router';
import store from './store/index';
import { app } from '@/main.js';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('./pages/SignUp.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: '',
        },
      ],
    },
    exact: true,
    component: () => import('./pages/Dashboard.vue'),
  },
  {
    path: '/matches/shortlisted',
    name: 'shortlistedmatches',
    meta: {
      breadcrumb: [{ parent: 'Matches', label: 'Shortlisted' }],
    },
    component: () => import('./pages/ShortlistedMatches.vue'),
  },
  {
    path: '/matches/allmatches',
    name: 'allmatches',
    meta: {
      breadcrumb: [{ parent: 'Matches', label: 'All matches' }],
    },
    component: () => import('./pages/AllMatches.vue'),
  },
  {
    path: '/mailbox/invitationReceived',
    name: 'Invitation Received',
    component: () => import('./pages/InvitationReceived.vue'),
    meta: {
      breadcrumb: [{ parent: 'Mail Box', label: 'Invitation Received' }],
    },
  },
  {
    path: '/mailbox/accepted',
    name: 'Accepted',
    component: () => import('./pages/InvitationAccepted.vue'),
    meta: {
      breadcrumb: [{ parent: 'Mail Box', label: 'Accepted' }],
    },
  },
  {
    path: '/mailbox/sentItems',
    name: 'Sent Items',
    component: () => import('./pages/InvitationSent.vue'),
    meta: {
      breadcrumb: [{ parent: 'Mail Box', label: 'Sent Items' }],
    },
  },
  {
    path: '/mailbox/declined',
    name: 'Declined',
    component: () => import('./pages/InvitationDeclined.vue'),
    meta: {
      breadcrumb: [{ parent: 'Mail Box', label: 'Declined' }],
    },
  },
  {
    path: '/mailbox/blocked',
    name: 'Blocked',
    component: () => import('./pages/BlockedUsers.vue'),
    meta: {
      breadcrumb: [{ parent: 'Mail Box', label: 'Blocked' }],
    },
  },
  {
    path: '/pending_approval',
    name: 'PendingApproval',
    meta: {
      breadcrumb: [{ parent: 'Pending Approvals', label: '' }],
    },
    component: () => import('./pages/PendingApprovals.vue'),
  },
  {
    path: '/profiles/nowraans',
    name: 'Nowraans',
    meta: {
      breadcrumb: [{ parent: 'Profiles', label: 'Nowraans' }],
    },
    component: () => import('./pages/Nowraan.vue'),
  },
  {
    path: '/profiles/nowrins',
    name: 'Nowrins',
    meta: {
      breadcrumb: [{ parent: 'Profiles', label: 'Nowrins' }],
    },
    component: () => import('./pages/Nowrin.vue'),
  },
  {
    path: '/mutual_profiles',
    name: 'MutualProfiles',
    meta: {
      breadcrumb: [{ parent: 'Mutual Profiles', label: '' }],
    },
    component: () => import('./pages/MutualProfiles.vue'),
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue'),
  },
  {
    path: '/myprofile',
    name: 'myprofile',
    meta: {
      breadcrumb: [{ parent: 'My Profile', label: '' }],
    },
    component: () => import('./pages/MyProfile.vue'),
  },

  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/Error.vue'),
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('./pages/NotFound.vue'),
  },
  {
    path: '/profile/:slugId',
    name: 'profile',
    component: () => import('./pages/profile'),
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import('./pages/ForgotPassword.vue'),
  },
  {
    path: '/feedbacks',
    name: 'Feedbacks',
    meta: {
      breadcrumb: [{ parent: 'Feedbacks', label: '' }],
    },
    component: () => import('./pages/Feedbacks.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('./pages/Home.vue'),
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import('./pages/PrivacyPolicy.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let adminPages = [
    'PendingApproval',
    'Stats',
    'Feedbacks',
    'Nowraans',
    'Nowrins',
  ];
  let userPages = [
    'allmatches',
    'shortlistedmatches',
    'Declined',
    'Sent Items',
    'Accepted',
    'Blocked',
    'Invitation Received',
    'myprofile',
  ];
  let unauthorisedPages = [
    'login',
    'SignUp',
    'ForgotPassword',
    'ResetPassword',
  ];
  let authorizedPages = ['profile', 'mailbox', 'dashboard'];
  let alwaysAccessiblePages = ['notfound', 'Home', 'PrivacyPolicy'];
  if (alwaysAccessiblePages.indexOf(to.name) !== -1) {
    if (to.name === 'Home' && Object.keys(to.query).length !== 0) {
      if (
        store.getters.authUser.is_admin &&
        adminPages.indexOf(to.query.page) !== -1
      ) {
        next({ path: '/' + to.query.page });
      } else if (authorizedPages.indexOf(to.query.page) === -1) {
        next({ name: 'Home' });
      } else {
        if (
          ['profile', 'mailbox'].includes(to.query.page) &&
          !to.query.arguments
        ) {
          next({ name: 'Dashboard' });
        } else if (to.query.page === 'profile' && to.query.arguments) {
          next({ name: 'profile', params: { slugId: to.query.arguments } });
        } else if (to.query.page === 'mailbox' && to.query.arguments) {
          switch (to.query.arguments) {
            case 'received':
              next({ name: 'Invitation Received' });
              break;
            case 'accepted':
              next({ name: 'Accepted' });
              break;
            case 'rejected':
              next({ name: 'Declined' });
              break;
            default:
              next({ name: 'Dashboard' });
              break;
          }
        } else if (to.query.page === 'dashboard') {
          next({ name: 'Dashboard' });
        }
      }
    } else {
      next();
    }
  } else if (!router.hasRoute(to.name)) {
    next({ name: 'notfound' });
  } else if (
    unauthorisedPages.indexOf(to.name) === -1 &&
    !store.getters.loggedIn
  ) {
    next({ name: 'login', params: to.query });
  } else if (
    (unauthorisedPages.indexOf(to.name) !== -1 && store.getters.loggedIn) ||
    (!store.getters.authUser?.is_admin && adminPages.includes(to.name)) ||
    (store.getters.authUser?.is_admin && userPages.includes(to.name))
  ) {
    next({ name: 'Dashboard' });
  } else if (to.name === 'profile') {
    if (to.params !== null) {
      if (
        to.params.slugId ===
        (store.getters.authUser !== undefined
          ? store.getters.authUser.slug
          : false)
      ) {
        next({ name: 'myprofile' });
      } else {
        next();
      }
    }
  } else if (
    unauthorisedPages.indexOf(to.name) === -1 &&
    !store.getters.authUser.is_approved
  ) {
    await store.dispatch('getCurrentUserProfile');
    if (store.getters.authUser.is_approved) {
      app.config.globalProperties.$moshaToast(
        'Your profile has been approved',
        {
          type: 'success',
          showIcon: true,
        }
      );
      next();
    } else if (['myprofile', 'Dashboard'].indexOf(to.name) === -1) {
      app.config.globalProperties.$moshaToast(
        `${
          store.getters.authUser.completed_percentage >= 90
            ? 'Your Profile is not yet approved'
            : 'Please fill all the required fields'
        }`,
        {
          type: 'warning',
          showIcon: true,
          duration: 1000,
        }
      );
      next({ name: 'myprofile' });
    } else {
      if (to.name === 'myprofile' && [undefined].indexOf(from.name) !== -1) {
        app.config.globalProperties.$moshaToast(
          `${
            store.getters.authUser.completed_percentage >= 90
              ? 'Your Profile is not yet approved'
              : 'Please fill all the required fields'
          }`,
          {
            type: 'warning',
            showIcon: true,
            duration: 1000,
          }
        );
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
