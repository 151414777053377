<template>
  <div class="layout-topbar">
    <div class="layout-topbar-logo p-m-0" @click="goToDashboard()">
      <img class="logo" src="/logo.png" alt="" />
    </div>

    <a class="layout-menu-button" href="#" @click="onMenuButtonClick">
      <i class="pi pi-bars"></i>
    </a>

    <ul v-show="desktopView" class="layout-topbar-menu">
      <li
        :class="[{ 'active-topmenuitem': activeTopbarItem === 'profile' }]"
        class="user-profile"
        @click="
          $emit('topbar-item-click', { originalEvent: $event, item: 'profile' })
        "
      >
        <a href="#">
          <div class="user-profile-info profile-link">
            <span class="user-profile-name p-text-capitalize">{{
              userName
            }}</span>
            <span class="user-profile-role">{{ userEmail }}</span>
          </div>
          <img class="logo" :src="userPhotoURL" alt="prestige-layout" />
        </a>
        <transition name="layout-submenu-container">
          <ul v-show="activeTopbarItem === 'profile'" class="fadeInDown">
            <li class="profile-detail">
              <div class="user-profile-info">
                <img class="logo" :src="userPhotoURL" alt="prestige-layout" />
                <div class="profile-info">
                  <span class="user-profile-name">{{ userName }}</span>
                  <span class="user-profile-role">{{ userEmail }}</span>
                </div>
              </div>
            </li>
            <li>
              <router-link v-if="canViewMyProfile" to="/myprofile">
                <div class="topbar-align">
                  <i class="pi pi-user p-mt-0 icon-mrg"></i>
                  <div class="menu-text">
                    <span class="menu-title">Profile</span>
                    <span class="menu-subtitle">Edit your profile</span>
                  </div>
                </div>
              </router-link>
              <a href="#" @click="showLogoutPopup()">
                <i class="pi pi-sign-out p-mt-0"></i>
                <div class="menu-text">
                  <span class="menu-title">Logout</span>
                </div>
              </a>
              <logout-dialog
                v-if="logoutConfirmation"
                :logout-dialog="logoutConfirmation"
                @hideDialog="hideLogoutPopup"
              />
            </li>
          </ul>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LogoutDialog from '@/components/LogoutDialog.vue';
import { redirectMixin } from '@/mixins/redirectMixin';
export default {
  components: {
    LogoutDialog,
  },
  mixins: [redirectMixin],
  props: {
    topbarMenuActive: Boolean,
    horizontal: Boolean,
    activeTopbarItem: String,
  },
  emits: ['topbar-item-click', 'menubutton-click', 'topbar-menubutton-click'],
  data() {
    return {
      desktopView: false,
      logoutConfirmation: false,
    };
  },
  computed: {
    ...mapGetters(['authUser', 'canViewMyProfile']),
    userEmail() {
      return this.authUser ? this.authUser.email : 'NA';
    },
    userName() {
      return this.authUser
        ? this.authUser.first_name + ' ' + this.authUser.last_name
        : 'NA';
    },
    userPhotoURL() {
      if (this.authUser.display_photo_thumb_url) {
        return this.authUser.display_photo_thumb_url;
      } else {
        return this.authUser.gender === 'female' ? '/bride.png' : '/groom.png';
      }
    },
  },
  mounted() {
    this.switchView();
    window.addEventListener('resize', this.switchView);
  },
  unmounted() {
    window.removeEventListener('resize', this.switchView);
  },
  methods: {
    switchView() {
      this.desktopView = window.innerWidth > this.$tabletView;
    },
    onMenuButtonClick(event) {
      this.$emit('menubutton-click', event);
    },
    showLogoutPopup() {
      this.logoutConfirmation = true;
      this.hideOverlayMenu();
    },
    hideLogoutPopup() {
      this.logoutConfirmation = false;
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: $tabletView) {
  .layout-topbar {
    margin: 0;
  }
}
.topbar-align {
  display: flex;
  align-items: center;
}
.icon-mrg {
  font-size: 17px;
  margin-right: 8px;
}
</style>
