<template>
  <body class="login-body">
    <div class="login-container">
      <div class="text-container">
        <div class="quote-sec">
          <p class="app-quote">
            From "just matched" <br />
            to "just married" <br />
            at <b>SoulSearch</b>
          </p>
        </div>
      </div>
      <div class="login-card">
        <div class="login-content">
          <div class="p-d-flex p-jc-center">
            <img class="app-icon" src="/Finalappicon.png" />
          </div>
          <h1 class="p-text-center">Sign In</h1>
          <form @submit.prevent="login()">
            <div class="email-container">
              <div class="login-input">
                <InputText
                  v-model="user.email"
                  type="email"
                  placeholder="Email"
                />
                <div class="p-error">
                  <small
                    v-if="submitClicked && v$.user.email.required.$invalid"
                    class="p-error"
                    >Enter This Field</small
                  >
                  <small
                    v-else
                    :class="
                      submitClicked && v$.user.email.email.$invalid
                        ? null
                        : 'hide-err'
                    "
                    class="p-error"
                    >Invalid Email</small
                  >
                </div>
              </div>
            </div>
            <div class="password-container">
              <div class="login-input">
                <InputText
                  v-model="user.password"
                  type="password"
                  placeholder="Password"
                  :autocomplete="false"
                />
                <div :class="submitClicked && v$.$error ? null : 'hide-err'">
                  <small
                    :class="
                      submitClicked && v$.user.password.required.$invalid
                        ? null
                        : 'hide-err'
                    "
                    class="p-error"
                    >Enter This Field</small
                  >
                </div>
              </div>
            </div>
            <div class="forgetpassword-container p-d-flex p-jc-center">
              <router-link to="/forgot_password">Forgot Password?</router-link>
            </div>
            <div>
              <Button
                class="p-text-bold p-mb-5 login_button"
                type="submit"
                :disabled="loading"
              >
                <span class="p-button-label"
                  ><i v-if="loading" class="fa fa-spinner fa-spin p-mr-2" />Sign
                  In</span
                >
              </Button>
            </div>
            <div class="p-mb-3 p-d-flex p-jc-center">
              <div>
                <label class="c-tc-l">Don't have an account? </label>
                <router-link to="/signup">Sign Up</router-link>
              </div>
            </div>
            <div class="divider p-mb-3">
              <span />
              <span>Or</span>
              <span />
            </div>
            <div class="">
              <a :href="googleSignInUrl" class="p-d-flex p-jc-center">
                <img alt="Logo" src="google-icon.png" class="login-icon" />
              </a>
              <!-- <img src="apple-icon.png" class="login-icon p-ml-3" /> -->
            </div>
          </form>
        </div>
      </div>
    </div>
    <Message
      v-if="display"
      severity="error"
      class="name1"
      :closable="false"
      :life="50000"
      :sticky="false"
      >{{ errorMessage }}</Message
    >
  </body>
</template>

<script>
import axios from 'axios';
import useValidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { mapActions } from 'vuex';
import { redirectMixin } from '@/mixins/redirectMixin';
export default {
  mixins: [redirectMixin],
  data() {
    return {
      v$: useValidate(),
      submitClicked: false,
      loading: false,
      errorMessage: '',
      display: false,
      user: {
        email: '',
        password: '',
      },
      validEmail: false,
      validPassword: false,
    };
  },
  computed: {
    googleSignInUrl() {
      return process.env.VUE_APP_RAILS_API_URL.replace('/api', '/auth/google');
    },
  },
  async mounted() {
    let login_status = this.$route.query.login_status;
    let that = this;
    if (login_status !== undefined && login_status === 'success') {
      await this.$http.get('/users/my_profile').then((response) => {
        that.loginUser(response.data.data.attributes);
      });
    }
    let email_confirmed = this.$route.params.email_confirmed;
    if (email_confirmed !== undefined && email_confirmed === 'true') {
      this.$moshaToast(
        'You have successfully verified your email. Please login again',
        { type: 'success', showIcon: true, timeout: 2000 }
      );
    } else if (email_confirmed !== undefined && email_confirmed === 'false') {
      this.$moshaToast('Email already verified or link expired', {
        type: 'danger',
        showIcon: true,
        timeout: 2000,
      });
    }
    this.v$.$validate();
  },
  validations() {
    return {
      user: {
        email: { required, email },
        password: { required },
      },
    };
  },
  methods: {
    ...mapActions(['setAuthUser']),
    login() {
      this.submitClicked = true;
      if (!this.v$.$error) {
        this.loading = true;
        let that = this;
        axios
          .post('login', this.user)
          .then((res) => {
            that.loginUser(res.data.data.attributes);
          })
          .catch((err) => {
            that.loading = false;
            if (err.response.data.message.includes('VERIFY_EMAIL')) {
              that.$moshaToast('Please verify your email address', {
                type: 'warning',
                showIcon: true,
                timeout: 2000,
              });
            } else {
              that.$moshaToast(
                that.getFormattedText(err.response.data.message),
                { type: 'danger', showIcon: true, timeout: 2000 }
              );
            }
          });
      }
    },
    loginUser(userData) {
      this.$store.commit('authUser', userData);
      this.$store.dispatch('getSelectOptions');
      userData.is_approved
        ? this.goToDashboard()
        : this.$router.push('/myprofile');
      this.loading = false;
    },
    getFormattedText(val) {
      let temp = val.split('.').pop();
      let a = '';
      temp
        .toLowerCase()
        .split('_')
        .map(
          (item) =>
            (a +=
              item.slice(0, 1).toUpperCase() +
              item.slice(1, item.length).toLowerCase() +
              ' ')
        );
      return a.trim();
    },
    showMessage(msg) {
      this.display = true;
      if (msg.includes('.')) {
        let arr = msg.split('.');
        this.errorMessage = arr[arr.length - 1];
      } else {
        this.errorMessage = msg;
      }
      setTimeout(() => {
        this.display = false;
        this.errorMessage = '';
      }, 2000);
    },
  },
};
</script>
<style>
.hide-err {
  visibility: hidden;
}
.name1 {
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>
<style scoped lang="scss">
.login_button {
  color: $backgroundColor;
  background-color: $primaryColor;
  padding: 0.7em;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.app-icon {
  width: 35%;
  margin: auto;
}
.login-icon {
  width: 11%;
  cursor: pointer;
}
.name_underline {
  padding-bottom: 14px;
  width: 5.4em;
  border-bottom: 3px solid $primaryColor;
}
.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    &:first-child {
      width: 100%;
      height: 1px;
      flex: 1;
      background: #ebecf1;
      display: inline-block;
    }
    &:last-child {
      width: 100%;
      height: 1px;
      flex: 1;
      background: #ebecf1;
      display: inline-block;
    }
    &:not(:first-child):not(:last-child) {
      padding: 0 2rem;
    }
  }
}
</style>
