<template>
  <div class="about-container">
    <!-- Header Nav bar -->

    <nav class="navbar bg-light">
      <div class="container menu-topbar">
        <div class="logo-container" @click="goToDashboard()">
          <img src="logo-color.png" class="ss-logo" />
        </div>
        <div class="navbar-collapse">
          <Button
            v-if="loggedIn"
            class="p-button-rounded dashboard-btn"
            label="Go To Dashboard"
            @click="goToDashboard()"
          />
          <Button
            v-else
            class="p-button-rounded"
            label="Login"
            @click="goToLogin()"
          />
        </div>
      </div>
    </nav>
    <!-- Block 1 -->
    <div class="block-container block-1">
      <div class="heading-text heading-text-width p-text-center">
        A new age matrimonial platform for every Indian
        <div>
          <a :href="homeLink">
            <Button type="button" class="block-1-btn p-mt-4">
              DOWNLOAD SOULSEARCH
            </Button>
          </a>
        </div>
      </div>
    </div>

    <!-- Block 2 -->
    <div class="block-2">
      <div class="heading-text block-2-text p-text-center p-my-6">
        Be found by the right one
      </div>
      <div class="p-grid block-2-content-container">
        <div class="block-2-content p-col-6 p-md-3 p-lg-3 p-xl-3">
          <img src="/assets/images/about/pink-1.png" class="block-2-img" />
          <div class="block-2-img-text">Free for woman</div>
        </div>
        <div class="block-2-content p-col-6 p-md-3 p-lg-3 p-xl-3">
          <img src="/assets/images/about/blue-1.png" class="block-2-img" />
          <div class="block-2-img-text">AI powered search engine</div>
        </div>
        <div class="block-2-content p-col-6 p-md-3 p-lg-3 p-xl-3">
          <img src="/assets/images/about/pink-2.png" class="block-2-img" />
          <div class="block-2-img-text">Human verification</div>
        </div>
        <div class="block-2-content p-col-6 p-md-3 p-lg-3 p-xl-3">
          <img src="/assets/images/about/blue-2.png" class="block-2-img" />
          <div class="block-2-img-text">Bank grade privacy & security</div>
        </div>
      </div>
    </div>

    <!-- Block 3 -->
    <div class="block-3-image-container">
      <div class="block-3 container">
        <div class="p-col-12 p-sm-6 p-text-center">
          <img src="/assets/images/about/thumb-1.png" class="block-3-img" />
        </div>
        <div class="p-col-12 p-sm-6">
          <div class="block-text-container">
            <div class="heading-text font-weight-600 p-mt-5">
              You soulsearch, we match
            </div>
            <div class="block-3-text p-d-flex p-mt-3">
              <span class="block-3-text">
                An AI powered search engine that will onboard you in seconds.
              </span>
            </div>
            <div class="block-3-text p-d-flex p-mt-3">
              <span class="">
                100% human verification-- as a best practice to handpick
                authentic profiles for the soulsearch community.
              </span>
            </div>
            <div class="block-3-text p-d-flex p-mt-3">
              <span class="">
                The soulsearch engine predicts and recommends profiles based on
                preferences such as religion, country, state and mother-tongue.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Block 4 -->
    <div class="block-3-image-container">
      <div class="block-3 container">
        <div class="p-col-12 p-sm-6 p-text-center">
          <img src="/assets/images/about/thumb-2.png" class="block-4-img" />
        </div>
        <div class="p-col-12 p-sm-6">
          <div class="block-text-container">
            <div class="heading-text font-weight-600 p-mt-5">
              Trust us with our identity
            </div>
            <div class="block-3-text p-d-flex p-mt-3">
              <span class="block-3-text">
                We ensure 100% security and privacy of your photos.
              </span>
            </div>
            <div class="block-3-text p-d-flex p-mt-3">
              <span class="">
                100% human verification-- as a best practice to handpick
                authentic profiles for the soulsearch community.
              </span>
            </div>
            <div class="block-3-text p-d-flex p-mt-3">
              <span class="">
                All photos will be watermarked after verification to ensure
                originality of profile pictures
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Block 5 -->
    <div class="block-3-image-container">
      <div class="block-3 container">
        <div class="p-col-12 p-sm-6 p-text-center">
          <img src="/assets/images/about/thumb-3.png" class="block-4-img" />
        </div>
        <div class="p-col-12 p-sm-6">
          <div class="block-text-container">
            <div class="heading-text font-weight-600 p-mt-5">
              Match. Connect. Complete.
            </div>
            <div class="block-3-text p-d-flex p-mt-3">
              <span class="block-3-text">
                We believe that every one deserves to find their betterhalf
                right here at soulsearch. All powered by our AI powered human
                interface.
              </span>
            </div>
            <div class="block-3-text p-d-flex p-mt-3">
              <span class="">
                Every profile once verified receives personalised
                recommendations based on preference and relevance
              </span>
            </div>
            <div class="block-3-text p-d-flex p-mt-3">
              <span class="">
                One can send interests to suitable profiles and on acceptance
                the matched profiles can connect via phone call to close work on
                potential matrimonial alliances.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- block-6 -->
    <div id="faq" class="block-3-image-container">
      <div class="block-3 container">
        <div class="p-col-12 p-sm-6 p-text-center p-d-flex p-flex-column">
          <img
            src="/assets/images/about/img-collage-1.png"
            class="block-4-img p-mb-2"
          />
          <div class="block-4-img-container p-d-flex">
            <div class="block-4-bottom-img p-d-flex p-jc-start">
              <img src="/assets/images/about/img-collage-2.png" />
            </div>
            <div class="block-4-bottom-img p-d-flex p-jc-center">
              <img src="/assets/images/about/img-collage-3.png" />
            </div>
            <div class="block-4-bottom-img p-d-flex p-jc-end">
              <img src="/assets/images/about/img-collage-4.png" />
            </div>
          </div>
        </div>
        <div class="p-col-12 p-sm-6">
          <div class="block-text-container">
            <div class="heading-text">FAQs</div>
            <Accordion>
              <AccordionTab header="How to get started?">
                Install our app or use the SoulSearch website and register with
                us. You can use google or apple login also. Then fill out your
                profile and wait for us to approve it.
              </AccordionTab>
              <AccordionTab
                header="Why am I always seeing 'You need to be approved before, you can access this page'?"
              >
                For security reasons, you cannot view others profiles or any
                other functions before we verify you. Sorry for the
                inconvenience
              </AccordionTab>
              <AccordionTab header="How can I reset my password?">
                You can reset your password by clicking the 'forgot password?'
                button in the login page and enter your email. next, we will
                send an OTP to your registered email. if you enter the correct
                OTP, you will be redirected to a page where you can reset your
                password
              </AccordionTab>
              <AccordionTab
                header="I accidentally send a interest to someone. How can I undo it?"
              >
                You need to go to the mailbox page in our app and click on the
                send interest tab. There you can delete the interest you send
              </AccordionTab>
              <AccordionTab header="can I save a profile for viewing later?">
                Yes, you can add a profile to shortlist by clicking on the heart
                icon on the Profile card. Once it’s done you can view all the
                profiles you shortlisted in shortlist tab in dashboard page
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>
    </div>

    <div class="block-container block-1 last-main-block">
      <div class="heading-text heading-text-width p-text-center">
        A new age matrimonial platform for every Indian
        <div>
          <a :href="homeLink">
            <Button type="button" class="block-1-btn p-mt-4">
              DOWNLOAD SOULSEARCH
            </Button>
          </a>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <footer
      class="
        footer
        page-footer
        font-small
        teal
        p-mt-4 p-d-flex p-flex-column p-jc-start
      "
    >
      <div class="container footer-container">
        <div class="p-grid p-d-flex p-jc-between">
          <div class="p-col-12 p-lg-4 p-md-4 p-grid">
            <div
              class="
                p-col-12
                p-lg-4
                p-md-4
                p-d-flex
                p-flex-column
                p-ai-center
                p-jc-start
                p-p-0
                footer-block footer-block-1
              "
              @click="goToDashboard()"
            >
              <img src="/Finalappicon.png" class="ss-logo" />
              <span class="ss-text">soulsearch</span>
              <span class="ss-sub-text">just matched to just married</span>
            </div>
            <div
              class="
                p-col-12
                p-lg-8
                p-md-8
                p-d-flex
                p-flex-column
                p-jc-start
                p-ai-start
                footer-block footer-block-2
              "
            >
              <span class="p-mb-2">Powered by Francium Tech</span>
              <span>Villa 29, SJH Groovy Woods,</span>
              <span>Srinivasa Nagar,</span>
              <span>6th Cross Street,</span>
              <span>Kazhipattur,</span>
              <span class="p-mb-1">Chennai 603103.</span>
              <a href="mailto:hello@soulsearch.in">hello@soulsearch.in</a>
            </div>
          </div>
          <div
            class="
              p-col-12
              p-lg-4
              p-md-4
              p-d-flex
              p-flex-column
              p-jc-start
              p-ai-center
              footer-block
              p-p-0
            "
          >
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.francium.soulsearch"
                ><img src="/google-play-badge.png" class="store-logo"
              /></a>
              <a
                href="https://apps.apple.com/in/app/find-your-soul/id1527447629"
                ><img src="/app-store-badge.jpg" class="store-logo"
              /></a>
            </div>
            <img
              src="/assets/images/about/web_application_badge.png"
              class="store-logo"
              @click="goToDashboard()"
            />
          </div>
          <div
            class="
              p-col-12
              p-lg-offset-1
              p-md-offset-1
              p-lg-3
              p-md-3
              p-d-flex
              p-flex-column
              p-jc-start
              p-ai-start
              footer-block footer-block-4
              p-p-0
            "
          >
            <a href="#faq">FAQ's</a>
            <span @click="toggleTC">Terms & Conditions</span>
            <span @click="togglePolicyDialog">Privacy Policy</span>
            <div class="p-d-flex p-ai-end">
              <a
                href="https://www.facebook.com/soulsearchindia/"
                target="_blank"
                ><i class="fab fa-facebook-f p-mr-3 p-mt-2 app-icon"
              /></a>
              <a
                href="https://www.instagram.com/soulsearchindia/"
                target="_blank"
                ><i class="fab fa-instagram app-icon"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright p-text-center p-py-3">
        © 2021
        <a href="https://francium.tech/"> Francium Tech</a>
      </div>
    </footer>
    <a
      id="back-to-top"
      href="#"
      class="btn btn-dark btn-lg back-to-top"
      role="button"
      ><i class="fa fa-angle-double-up"></i
    ></a>
    <tc-policy-dialog
      v-if="displayTC"
      :content="contentTC"
      @whenClose="toggleTC"
    />
    <tc-policy-dialog
      v-if="showPolicy"
      :content="privacyPolicy"
      @whenClose="togglePolicyDialog"
    />
  </div>
</template>
<script>
import TcPolicyDialog from '@/components/TcPolicyDialog.vue';
import { redirectMixin } from '@/mixins/redirectMixin';
import { mapGetters } from 'vuex';

export default {
  components: {
    TcPolicyDialog,
  },
  mixins: [redirectMixin],
  data() {
    return {
      displayTC: false,
      contentTC: '',
      homeLink: '',
      privacyPolicy: '',
      showPolicy: false,
    };
  },
  computed: {
    ...mapGetters(['loggedIn']),
  },
  mounted() {
    this.getTC();
    this.getHomeLink();
    this.$http.get('privacy_policy').then((res) => {
      this.privacyPolicy = res.data.content;
    });
  },
  methods: {
    togglePolicyDialog() {
      this.showPolicy = !this.showPolicy;
    },
    getHomeLink() {
      this.$http.get('/home_fdl').then((res) => {
        this.homeLink = res.data.link;
      });
    },
    toggleTC() {
      this.displayTC = !this.displayTC;
    },
    getTC() {
      let that = this;
      this.$http.get('get_policy').then((res) => {
        that.contentTC = res.data.content;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$aboutPrimaryColor: #fff;
img {
  max-width: 100%;
}
.about-container {
  background-color: $aboutPrimaryColor;
  line-height: 1.5;
}
.content {
  flex: 1 0 auto;
}
.content .navbar-collapse {
  flex-grow: 0;
}
.navbar-collapse {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar {
  z-index: 2;
  overflow: hidden;
  background-color: $aboutPrimaryColor;
  position: fixed;
  top: 0;
  width: 100%;
}
// footer
.footer {
  height: 300px;
  color: black;
  font-weight: 600;
  .container {
    .app-icon {
      font-size: 25px;
    }
    .footer-block-1 {
      text-align: center;
      cursor: pointer;
    }
    .footer-block-2 {
      padding: 0 0 0 10px;
    }
    .footer-block-4 {
      span {
        cursor: pointer;
      }
      a {
        color: black;
      }
    }
  }
}

.container {
  width: 1140px;
  max-width: 90%;
  margin: auto;
  padding-right: 0px;
  padding-left: 0px;
  letter-spacing: 0.9px;
}
.menu-topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}
.logo-container {
  cursor: pointer;
  padding: 12px 0;
}
.store-logo {
  width: 100px;
  height: 35px;
  margin-right: 3px;
  cursor: pointer;
}
.ss-logo {
  height: 50px;
  width: auto;
}
.ss-pt {
  padding-top: 60px;
}
.ss-pb {
  padding-bottom: 60px;
}
.ss-text {
  color: $primaryColor;
  font-weight: 900;
  font-size: 12px;
}
.ss-sub-text {
  font-size: 8px;
  color: $primaryLightColor;
}
.font-weight-600 {
  font-weight: 600;
}
.app-store-badge {
  height: auto;
  width: 120px;
}
.play-store-badge {
  height: auto;
  width: 140px;
}
.bg-color {
  background-color: #ffe6f7;
}
.img-h-auto {
  height: auto;
  max-height: 500px;
  padding: 0;
}
.block-container {
  position: relative;
  display: flex;
  justify-content: center;
}
.block-bg {
  width: 100%;
  height: auto;
}
.heading-text {
  font-weight: 900;
  line-height: 4.2rem;
  color: $aboutPrimaryColor;
  font-size: 3.5rem;
}
.heading-text-width {
  width: 43rem;
}
.block-1 {
  margin-top: 5rem;
  background: url(/assets/images/about/about-1.png);
  background-position: center;
  background-size: cover;
  min-height: 90vh;
  display: flex;
  align-items: flex-end;
  padding-bottom: 60px;
}
.block-1-text {
  color: #333;
}
.block-1-btn {
  color: $aboutPrimaryColor;
  width: 13rem;
  background-color: transparent;
  border: 3px solid $aboutPrimaryColor;
  border-radius: 10px;
  font-weight: 900;
  margin-top: 5px;
  font-size: 1.4rem;
  box-shadow: 0 0 10px 3.5px $aboutPrimaryColor,
    inset 0 0 10px 3.5px $aboutPrimaryColor;
}
.block-1-btn:hover {
  background-color: transparent; /* Green */
  border: 3px solid $aboutPrimaryColor;
  box-shadow: 0 0 10px 5px $aboutPrimaryColor,
    inset 0 0 10px 5px $aboutPrimaryColor;
}
.block-2 {
  background: url(/assets/images/about/about-2.png);
  background-size: cover;
  background-position: right;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 80px;
  margin-top: 50px;
}
.block-2-content-container {
  width: 80rem;
  max-width: 90%;
  justify-content: space-between;
}
.block-2-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 16rem;
}
.block-2-img {
  width: 9rem;
  padding-bottom: 1.5rem;
}
.block-2-img-text {
  max-width: 12rem;
  font-size: 1.4rem;
  font-weight: 900;
  color: $aboutPrimaryColor;
  text-align: center;
}
.block-3-image-container {
  background: url(/assets/images/about/about-3.png);
  background-size: cover;
  background-position: center;
  min-height: 90vh;
  margin: 50px 0;
  display: flex;
  align-items: center;
}
.block-3 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $aboutPrimaryColor;
}
.block-3-img {
  width: 30rem;
}
.block-3-text {
  font-size: 1.3rem;
}
.block-4-img-container {
  width: 100% !important;
  justify-content: space-between;
}
.block-4-bottom-img {
  img {
    width: 97%;
  }
}
.block-4 .block-center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.last-main-block {
  background: url(/assets/images/about/about-4.png);
  background-position: center;
  background-size: cover;
}
.w-85 {
  width: 85% !important;
}
.fran-logo {
  width: 150px;
  height: auto;
  object-fit: cover;
}
.trend-logo {
  width: 60px;
  height: auto;
  object-fit: cover;
}
.zen-logo {
  width: 60px;
  height: auto;
  object-fit: cover;
}
.pal-logo {
  width: 100px;
  height: auto;
  object-fit: cover;
}
.carousel-indicators li {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #ff89dc;
  border-radius: 50%;
  display: inline-block;
}

.prev,
.next {
  display: inline-block;
  width: 50px;
  height: 40px;
  margin: 6px;
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  text-align: center;
  transition: 0.6s ease;
}

.prev i,
.next i {
  top: calc(50% - 14px);
  position: relative;
  color: #ff89dc;
}

.next {
  right: 0;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.block-6 .caro-width {
  width: 60%;
}

.block-6 .author-name {
  font-size: 10px;
}
.bs-accordion .btn-link {
  color: black;
}

.bs-accordion .btn-link:hover {
  color: black;
}

.bs-accordion .card {
  border-color: #ffe6f7;
}
.bs-accordion .card-header {
  background-color: transparent;
}
.link-small a {
  color: black;
}
.link-small {
  list-style-type: none;
}
.content .bg-light {
  background-color: white !important;
}
.back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: none;
  opacity: 0.7;
}
.back-to-top:hover {
  opacity: 1;
}
.tw-ic {
  color: #50abf1;
}

.li-ic {
  color: #0477b5;
}

.fb-ic {
  color: #4967aa;
}
.nav-link {
  font-weight: 500;
  color: black !important;
}
.nav-link:hover {
  font-weight: bold;
}
.bs-accordion .btn-link:hover {
  text-decoration: none;
}
.clearfix {
  display: none;
}

/*   Large screen / desktop */
@media (max-width: $tabletView) {
  .block-2 {
    background-position: left 80% top 1%;
  }
  .block-3-image-container {
    background-position: left 70% top 1%;
  }
  .last-main-block {
    background-position: left 30% top 1%;
  }
  .heading-text {
    line-height: 3.8rem;
    font-size: 3rem;
  }
  .block-2-img {
    width: 5rem;
  }
  .block-2-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: unset;
  }
  .block-text {
    font-size: 2rem;
  }
  .block-2 .w-50 {
    width: 75% !important;
  }
  .block-6 .caro-width {
    width: 70%;
  }
  body {
    font-size: 12px;
  }
  .container {
    max-width: 95%;
  }
}

/*  Medium screen / tablet */
@media (max-width: 775px) {
  .footer {
    min-height: 300px;
    height: initial;
    .container {
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        .footer-block {
          align-items: center !important;
          margin: 0.5rem 0;
          width: 100%;
        }
        .footer-block-2 {
          min-width: 250px;
          max-width: 350px;
          padding: unset;
        }
        .footer-block-4 {
          div {
            flex-direction: row;
            align-items: flex-end;
          }
        }
      }
    }
  }
  .block-3-image-container {
    background-position: left 65% top 1%;
  }
  .block-2-content-container {
    margin-top: 2rem;
  }
  .block-2 .w-50 {
    width: 100% !important;
  }
  .block-6 .caro-width {
    width: 100%;
  }
  .clearfix {
    display: block;
  }
}

/*  Small screen / phone */
@media (max-width: 576px) {
  .heading-text {
    line-height: 3rem;
    font-size: 2rem;
  }
  .block-1,
  .block-2,
  .last-main-block {
    min-height: 85vh;
  }
  .block-2 {
    background-position: left 74% top 1%;
  }
  .last-main-block {
    background-position: left 35% top 1%;
  }
  .block-3-image-container {
    min-height: 90vh;
    height: initial;
    background-position: left 60% top 1%;
  }
  .block-3 {
    height: 100%;
    flex-direction: column;
  }
  .block-3-img {
    margin-top: 35px;
    width: 17.5rem;
  }
  .block-text-container {
    padding-bottom: 35px;
  }
  .block-3-text {
    font-size: 1.1rem;
  }
  .block-4-img {
    margin-top: 35px;
  }
  .block-2 .w-50 {
    width: 100% !important;
  }
  .block-6 .caro-width {
    width: 100%;
  }
  .img-h-auto {
    max-height: 400px;
  }
  body {
    font-size: 13px;
  }
}
@media (max-width: 400px) {
  .block-1,
  .block-2,
  .block-3-image-container,
  .last-main-block {
    min-height: 77vh;
  }
}
</style>

<style lang="scss">
@media (max-width: $mobileView) {
  .navbar-collapse {
    .dashboard-btn {
      .p-button-label {
        display: none;
      }
      &:after {
        content: 'Dashboard';
      }
    }
  }
}
#faq {
  .block-text-container {
    .heading-text {
      text-align: center;
    }
    .p-accordion {
      .p-accordion-tab {
        .p-accordion-header:hover {
          border: none;
          box-shadow: none;
          color: white;
        }
        .p-accordion-header > a,
        div > .p-accordion-content {
          font-size: 1.3rem;
          background: transparent !important;
          border: none;
          color: white;
          &:focus {
            border: none;
            box-shadow: none;
            color: white;
          }
        }
      }
    }
  }
}

.block-text-container
  .p-accordion
  > .p-accordion-tab
  > div
  > .p-accordion-content {
  background: transparent !important;
  border: none;
  color: white;
}
</style>
