export const formFieldMixin = {
  methods: {
    splitUnderscore(data) {
      return data ? data.split('_').join(' ').toString() : null;
    },
    passwordValidator(val) {
      return new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{2,}$/).test(val);
    },
  },
};
