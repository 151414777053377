import router from '@/router';

export const redirectMixin = {
  methods: {
    goToDashboard() {
      router.push({ name: 'Dashboard' });
    },
    goToLogin() {
      router.push({ name: 'login' });
    },
  },
};
