<template>
  <div :class="containerClass" @click="onDocumentClick">
    <Button
      v-if="canSubmitFeedback"
      class="feedback-btn"
      label="Feedback"
      @click="updateShowFeedbackForm(true)"
    />
    <div class="layout-content-wrapper p-p-0">
      <AppTopBar
        :topbar-menu-active="topbarMenuActive"
        :horizontal="layoutMode === 'horizontal'"
        :active-topbar-item="activeTopbarItem"
        @menubutton-click="onMenuButtonClick"
        @topbar-menubutton-click="onTopbarMenuButtonClick"
        @topbar-item-click="onTopbarItemClick"
      ></AppTopBar>

      <div class="layout-menu-container" @click="onMenuClick">
        <div class="overlay-menu-button" @click="onMenuButtonClick">
          <div class="overlay-menu-button-bars">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="overlay-menu-button-times">
            <span></span>
            <span></span>
          </div>
        </div>

        <div class="layout-menu-wrapper fadeInDown">
          <div v-show="windowWidth <= $tabletView">
            <div class="card">
              <div class="user-profile-info">
                <img class="logo" :src="userPhotoURL" alt="prestige-layout" />
                <div class="profile-info">
                  <span class="user-profile-name p-text-capitalize">{{
                    userName
                  }}</span
                  ><br />
                  <span class="user-profile-role">{{ userEmail }}</span>
                </div>
              </div>
            </div>
          </div>
          <AppMenu
            :layout-mode="layoutMode"
            :active="menuActive"
            @menuitem-click="onMenuItemClick"
            @root-menuitem-click="onRootMenuItemClick"
          ></AppMenu>
          <div v-show="windowWidth <= $tabletView">
            <div class="logout-card" @click="showLogoutPopup">
              <div class="card-background">
                <div class="logout">
                  <i class="pi pi-sign-out icon-margin"></i>
                  <p>LOGOUT</p>
                </div>
              </div>
            </div>
            <logout-dialog
              v-if="logoutConfirmation"
              :logout-dialog="logoutConfirmation"
              @hideDialog="hideLogoutPopup"
            />
          </div>
        </div>
      </div>

      <AppActionBar />

      <div class="layout-content">
        <router-view />
      </div>

      <div class="layout-mask"></div>

      <FeedbackFormDialog />
    </div>
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppActionBar from './AppActionBar';
import EventBus from './event-bus';
import { mapGetters, mapActions } from 'vuex';
import FeedbackFormDialog from '@/utilities/FeedbackFormDialogue';
import LogoutDialog from '@/components/LogoutDialog.vue';

export default {
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppActionBar: AppActionBar,
    FeedbackFormDialog: FeedbackFormDialog,
    LogoutDialog,
  },
  props: {
    theme: String,
    layout: String,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      layoutMode: 'horizontal',
      wrapperMode: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      menuActive: false,
      configActive: false,
      configClick: false,
      logoutConfirmation: false,
    };
  },
  computed: {
    ...mapGetters(['authUser', 'canSubmitFeedback']),
    userEmail() {
      return this.authUser ? this.authUser.email : 'NA';
    },
    userName() {
      return this.authUser
        ? this.authUser.first_name + ' ' + this.authUser.last_name
        : 'NA';
    },
    userPhotoURL() {
      return this.authUser?.display_photo_thumb_url !== null
        ? this.authUser?.display_photo_thumb_url
        : '/default-user.png';
    },
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-menu-overlay': this.layoutMode === 'overlay',
          'layout-menu-overlay-active': this.overlayMenuActive,
          'layout-mobile-active': this.mobileMenuActive,
          'layout-menu-horizontal': this.layoutMode === 'horizontal',
          'p-input-filled': this.$appState.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
        {
          'layout-fullwidth': this.$appState.fullWidth,
        },
      ];
    },
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth);

      this.getWindowWidth();
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  methods: {
    ...mapActions(['updateShowFeedbackForm']),
    getWindowWidth() {
      // commented this line as clientwidth is not mwatching with window.innerwidth in ubuntu
      // this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= this.$tabletView) {
        this.$appState.fullWidth = true;
      } else {
        this.$appState.fullWidth = false;
      }
    },
    onDocumentClick() {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isHorizontal()) {
          this.menuActive = false;
          EventBus.emit('reset-active-index');
        }

        this.hideOverlayMenu();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.topbarItemClick = false;
      this.menuClick = false;
      this.configClick = false;
    },
    onProfileClick(event) {
      this.profileExpanded = !this.profileExpanded;
      if (this.isHorizontal()) {
        EventBus.emit('reset-active-index');
      }

      event.preventDefault();
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit('reset-active-index');
        this.hideOverlayMenu();

        if (this.isHorizontal()) {
          this.menuActive = false;
        }
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;

      this.topbarMenuActive = false;
      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          this.overlayMenuActive = !this.overlayMenuActive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onTopbarMenuButtonClick(event) {
      this.topbarItemClick = true;
      this.topbarMenuActive = !this.topbarMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === event.item) this.activeTopbarItem = null;
      else this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.mobileMenuActive = false;
    },
    isDesktop() {
      return window.innerWidth > this.$tabletView;
    },
    isHorizontal() {
      return this.layoutMode === 'horizontal';
    },
    onLayoutModeChange(layoutMode) {
      this.layoutMode = layoutMode;
      this.staticMenuDesktopInactive = false;
      this.overlayMenuActive = false;
    },
    onThemeChange(theme) {
      this.$emit('theme-change', theme);
    },
    onLayoutChange(layout) {
      this.$emit('layout-change', layout);
    },
    onConfigClick() {
      this.configClick = true;
    },
    onConfigButtonClick() {
      this.configActive = !this.configActive;
      this.configClick = true;
    },
    isMobile() {
      return window.innerWidth <= this.$tabletView;
    },
    showLogoutPopup() {
      this.logoutConfirmation = true;
      this.hideOverlayMenu();
    },
    hideLogoutPopup() {
      this.logoutConfirmation = false;
    },
  },
};
</script>

<style lang="scss">
@import 'public/assets/layout/css/variables.scss';
@import 'public/assets/theme/theme.scss';
@import 'public/assets/layout/css/layout.scss';
@import './App.scss';
</style>
<style scoped lang="scss">
.card {
  background-color: white;
  padding: 13px;
}
.logout-card {
  background-color: white;
  padding: 13px;
  padding-left: 18px;
}
.user-profile-info {
  display: flex;
  align-items: center;
}
.logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.profile-info {
  padding-left: 20px;
  font-size: 18px;
  word-break: break-all;
}
.logout {
  display: flex;
  align-items: center;
  height: 15px;
}
.icon-margin {
  margin-right: 7px;
  color: $primaryLabelColor;
}
p {
  color: $primaryLabelColor;
  font-size: 13px;
}
.logout-card:hover {
  background-color: $secondaryBackgroundColor;

  cursor: pointer;
}
.logout-card:hover p {
  color: $primaryColor;
}
.logout-card:hover .icon-margin {
  color: $primaryColor;
}
.feedback-btn {
  position: fixed !important;
  right: 0;
  bottom: 25vh;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: bottom right;
  z-index: 10;
}
@media screen and (max-width: $tabletView) {
  .feedback-btn {
    opacity: 0.7;
  }
}
</style>
