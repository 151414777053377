<template>
  <body class="login-body">
    <div class="login-container">
      <div class="text-container">
        <div class="quote-sec">
          <p class="app-quote">
            From "just matched" <br />
            to "just married" <br />
            at <b>SoulSearch</b>
          </p>
        </div>
      </div>
      <div class="p-col-12 p-lg-4 login-card">
        <div class="login-content">
          <img src="/Finalappicon.png" />
          <div v-if="status === 'OPEN'">
            <h1 class="p-text-center">Forgot Password</h1>
            <form @submit.prevent="sendOtp()">
              <div class="email-container">
                <div class="login-input">
                  <InputText
                    v-model="email"
                    autofocus
                    type="email"
                    placeholder="Email"
                  />
                  <div class="p-error">
                    <small
                      v-if="sendOtpClicked && v$.email.required.$invalid"
                      class="p-error"
                      >E-mail required</small
                    >
                    <small
                      v-else
                      :class="
                        sendOtpClicked && v$.email.email.$invalid
                          ? null
                          : 'hide-err'
                      "
                      class="p-error"
                      >Invalid e-mail address</small
                    >
                  </div>
                </div>
              </div>
              <div>
                <Button
                  class="p-text-bold p-mb-5 login_button"
                  type="submit"
                  :disabled="loading"
                >
                  <span class="p-button-label"
                    ><i
                      v-if="loading"
                      class="fa fa-spinner fa-spin p-mr-2"
                    />Send OTP</span
                  >
                </Button>
              </div>
              <div class="forgetpassword-container p-d-flex p-jc-center">
                <div>
                  <label class="c-tc-l">Remember password? </label>
                  <router-link to="/login">Sign In</router-link>
                </div>
              </div>
            </form>
          </div>
          <div v-if="status === 'OTP_SENT'">
            <h1 class="p-text-center">OTP Verification</h1>
            <form @submit.prevent="verify()">
              <div class="email-container">
                <div class="login-input">
                  <InputText v-model="entered_otp" placeholder="6-digit OTP" />
                  <div class="p-error">
                    <small
                      v-if="
                        verifyOtpClicked && v$.entered_otp.required.$invalid
                      "
                      class="p-error"
                      >Enter OTP</small
                    >
                    <small
                      v-else
                      :class="
                        verifyOtpClicked &&
                        (v$.entered_otp.minLength.$invalid ||
                          v$.entered_otp.maxLength.$invalid)
                          ? null
                          : 'hide-err'
                      "
                      class="p-error"
                      >Enter valid OTP</small
                    >
                  </div>
                </div>
                <div>
                  <Button
                    class="p-text-bold p-mb-3 login_button"
                    type="submit"
                    :disabled="loading"
                  >
                    <span class="p-button-label"
                      ><i
                        v-if="loading"
                        class="fa fa-spinner fa-spin p-mr-2"
                      />Verify</span
                    >
                  </Button>
                </div>

                <div
                  class="
                    p-d-flex p-jc-center
                    forgetpassword-container
                    p-mt-4 p-mb-0
                  "
                >
                  <p>Didn't receive the OTP?</p>
                  <vue-countdown
                    v-if="countDownEnd === false"
                    v-slot="{ minutes, seconds }"
                    :time="60 * 1000"
                    class="count-down"
                    @end="countDownEnd = true"
                  >
                    {{
                      minutes.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })
                    }}
                    :
                    {{
                      seconds.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })
                    }}
                  </vue-countdown>
                  <p
                    v-if="countDownEnd === true"
                    class="resend-btn"
                    @click="
                      sendOtp();
                      countDownEnd = false;
                    "
                  >
                    Resend again
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div v-if="status === 'VERIFIED'">
            <h1 class="p-text-center">Reset Password</h1>
            <form @submit.prevent="resetPassword()">
              <div class="email-container">
                <div class="login-input">
                  <InputText
                    v-model="new_password"
                    type="password"
                    placeholder="New Password"
                  />
                  <div class="p-error">
                    <small
                      v-if="v$.new_password.minLength.$invalid"
                      class="p-error"
                    >
                      <li>Password must contain minumum 8 characters</li>
                    </small>
                    <small
                      v-if="
                        v$.new_password.case.$invalid &&
                        new_password.length >= 2
                      "
                      class="p-error"
                    >
                      <li>
                        must have at least 1 uppercase, 1 lowercase and 1 number
                      </li>
                    </small>
                    <small
                      v-else
                      :class="
                        verifyClicked && v$.new_password.required.$invalid
                          ? null
                          : 'hide-err'
                      "
                      >Password can't be empty</small
                    >
                  </div>
                </div>
                <div class="login-input">
                  <InputText
                    v-model="confirm_password"
                    type="password"
                    placeholder="Re-enter Password"
                  />
                  <div class="p-error">
                    <small
                      v-if="
                        verifyClicked && v$.confirm_password.required.$invalid
                      "
                      class="p-error"
                      >Password can't be empty</small
                    >
                    <small
                      v-else
                      :class="
                        verifyClicked && v$.confirm_password.sameAs.$invalid
                          ? null
                          : 'hide-err'
                      "
                      class="p-error"
                      >Passwords do not match</small
                    >
                  </div>
                </div>
              </div>
              <div>
                <Button
                  class="p-text-bold p-mb-5 login_button"
                  type="submit"
                  label="Verify"
                  :disabled="loading"
                >
                  <span class="p-button-label"
                    ><i
                      v-if="loading"
                      class="fa fa-spinner fa-spin p-mr-2"
                    />Verify</span
                  >
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import useValidate from '@vuelidate/core';
import { formFieldMixin } from '@/mixins/formFieldMixin';
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from '@vuelidate/validators';
export default {
  mixins: [formFieldMixin],
  data() {
    return {
      loading: false,
      verifyOtpClicked: false,
      countDownEnd: false,
      sendOtpClicked: false,
      verifyClicked: false,
      v$: useValidate(),
      email: '',
      entered_otp: '',
      new_password: '',
      confirm_password: '',
      status: 'OPEN',
    };
  },
  mounted() {
    this.v$.$validate();
  },
  validations() {
    return {
      email: { required, email },
      new_password: {
        required,
        minLength: minLength(8),
        case(val) {
          return this.passwordValidator(val);
        },
      },
      confirm_password: {
        required,
        sameAs: sameAs(this.new_password),
      },
      entered_otp: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    };
  },
  methods: {
    sendOtp() {
      this.v$.$validate();
      this.sendOtpClicked = true;
      if (!this.v$.email.$error) {
        let that = this;
        this.loading = true;
        this.$http
          .post('/password_reset', { email: this.email })
          .then((res) => {
            if (res.data.message.includes('SUCCESS')) {
              that.status = 'OTP_SENT';
              that.loading = false;
              that.$moshaToast('OTP sent to ' + that.email, {
                type: 'success',
                showIcon: true,
                timeout: 2000,
              });
            } else if (res.data.message.includes('NOT_FOUND')) {
              that.$moshaToast('Please enter Valid email', {
                type: 'danger',
                showIcon: true,
                timeout: 2000,
              });
            }
          })
          .catch(() => {
            that.$moshaToast('Please enter Valid email', {
              type: 'danger',
              showIcon: true,
              timeout: 2000,
            });
            that.loading = false;
          });
      }
    },
    verify() {
      this.v$.$validate();
      this.verifyOtpClicked = true;
      if (!this.v$.entered_otp.$error) {
        let that = this;
        this.loading = true;
        this.$http
          .post('/password_reset/verify_token', {
            email: this.email,
            entered_otp: this.entered_otp,
          })
          .then((res) => {
            if (res.data.message.includes('VERIFIED')) {
              that.status = 'VERIFIED';
              that.loading = false;
              that.$moshaToast('Verification Successfully', {
                type: 'success',
                showIcon: true,
                timeout: 2000,
              });
            }
          })
          .catch(() => {
            that.$moshaToast('Verification Failed', {
              type: 'danger',
              showIcon: true,
              timeout: 2000,
            });
            that.loading = false;
          });
      }
    },
    resetPassword() {
      this.v$.$validate();
      this.verifyClicked = true;
      if (!this.v$.new_password.$error && !this.v$.confirm_password.$error) {
        let that = this;
        this.loading = true;
        this.$http
          .patch('/password_reset', {
            email: this.email,
            new_password: this.new_password,
          })
          .then((res) => {
            if (res.data.message.includes('SUCCESS')) {
              that.$router.push('/login');
              that.$moshaToast('Password reset successfully', {
                type: 'success',
                showIcon: true,
                timeout: 2000,
              });
              that.loading = false;
            }
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hide-err {
  visibility: hidden;
}
.resend-btn {
  margin-left: 4px;
  color: $primaryColor !important;
  cursor: pointer;
}
.count-down {
  margin-left: 4px;
  color: $primaryColor;
  font-size: 17px;
}
.login_button {
  margin-top: 20px;
  color: $backgroundColor;
  background-color: $primaryColor;
  padding: 0.7em;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
img {
  width: 30%;
  margin-left: 35%;
}
.name_underline {
  padding-bottom: 14px;
  width: 5.4em;
  border-bottom: 3px solid $primaryColor;
}
</style>
