<template>
  <AppSubmenu
    class="layout-menu clearfix"
    :items="model"
    :layout-mode="layoutMode"
    :menu-active="active"
    :root="true"
    :parent-menu-item-active="true"
  />
</template>

<script>
import AppSubmenu from './AppSubmenu';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppSubmenu: AppSubmenu,
  },
  props: {
    layoutMode: { type: String, default: '' },
    active: Boolean,
  },
  data() {
    return {
      model: [
        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'authUser',
      'canViewAdminStats',
      'canViewPendingApproval',
      'canViewFeedbacks',
      'canSubmitFeedback',
      'canViewMatches',
      'canViewMailBox',
      'canViewMyProfile',
      'canViewProfiles',
    ]),
  },
  mounted() {
    const allPages = [
      {
        label: 'Profiles',
        icon: 'pi pi-fw pi-users',
        canAccess: this.canViewProfiles,
        items: [
          {
            label: 'Nowraans',
            icon: 'fas fa-user',
            to: '/profiles/nowraans',
          },
          { label: 'Nowrins', icon: 'far fa-user', to: '/profiles/nowrins' },
        ],
      },
      {
        label: 'Pending Approvals',
        icon: 'pi pi-fw pi-user',
        to: '/pending_approval',
        canAccess: this.canViewPendingApproval,
      },
      {
        label: 'Feedbacks',
        icon: 'pi pi-fw pi-comments',
        to: '/feedbacks',
        canAccess: this.canViewFeedbacks,
      },
      {
        label: 'Matches',
        icon: 'pi pi-fw pi-users',
        canAccess: this.canViewMatches,
        items: [
          {
            label: 'All matches',
            icon: 'fas fa-users',
            to: '/matches/allmatches',
          },
          {
            label: 'Shortlisted',
            icon: 'fas fa-user-friends',
            to: '/matches/shortlisted',
          },
        ],
      },
      {
        label: 'Mail Box',
        icon: 'pi pi-fw pi-envelope',
        canAccess: this.canViewMailBox,
        items: [
          {
            label: 'Invitation Received',
            icon: 'pi pi-fw pi-envelope',
            to: '/mailbox/invitationReceived',
          },
          {
            label: 'Accepted',
            icon: 'pi pi-fw pi-check-circle',
            to: '/mailbox/accepted',
          },
          {
            label: 'Sent Items',
            icon: 'pi pi-fw pi-send',
            to: '/mailbox/sentItems',
          },
          {
            label: 'Declined',
            icon: 'pi pi-fw pi-times-circle',
            to: '/mailbox/declined',
          },
          {
            label: 'Blocked',
            icon: 'pi pi-fw pi-ban',
            to: '/mailbox/blocked',
          },
        ],
      },
      {
        label: 'My Profile',
        canAccess: this.canViewMyProfile,
        icon: 'pi pi-fw pi-user',
        to: '/myprofile',
      },
    ];
    for (const page in allPages) {
      if (allPages[page].canAccess) {
        this.model.push(allPages[page]);
      }
    }
  },
};
</script>

<style scoped></style>
