<template>
  <editor
    :init="{
      branding: false,
      height: 300,
      menubar: false,
      statusbar: showToolbar ? true : false,
      toolbar: showToolbar
        ? 'undo redo |' +
          'bold italic |' +
          'bullist numlist | outdent indent |' +
          'alignleft aligncenter alignright alignjustify |' +
          'removeformat'
        : false,
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; } p { margin: 0; }',
    }"
  >
  </editor>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
export default {
  components: {
    editor: Editor,
  },
  props: {
    showToolbar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.tox-notification {
  display: none !important;
}
</style>
