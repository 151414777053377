<template>
  <Dialog
    v-model:visible="showDialog"
    :modal="true"
    :closable="false"
    :show-header="false"
    :draggable="false"
    :close-on-escape="true"
    class="dialog-tc"
  >
    <div class="p-mx-4 p-pt-6 content-tc" v-html="content" />
    <template #footer>
      <Button
        label="Close"
        class="p-button-outlined"
        @click="whenCloseDialog"
      /> </template
  ></Dialog>
</template>

<script>
export default {
  props: ['content'],
  emits: ['whenClose'],
  data() {
    return {
      showDialog: true,
    };
  },
  methods: {
    whenCloseDialog() {
      this.$emit('whenClose');
    },
  },
};
</script>
